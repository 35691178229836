@import '../../styles/customMediaQueries.css';

.tabs {
  padding-top: 2px;

  display: flex;
  flex-direction: row;
  overflow-x: auto;

  @media (--viewportMedium) {
    padding-top: 12px;
    padding-top: 20px;
  }

  @media (--viewportLarge) {
    padding-top: 24px;
    flex-direction: column;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}
